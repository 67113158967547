// i18next-extract-mark-ns-start markets-motorcycle

import { MOTORCYCLE_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const MarketMotorcyclePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Modelling the future of motorcycle propulsion</h2>

						<p>
							Motorcycles are about passion, freedom, exhilaration and the feel of being at one with the machine.
							Raw engineering performance, sound and power now however need to be coupled with technology to meet
							the requirements of tomorrow’s market with electronics such as ‘ride-by-wire’ improving comfort and
							safety by enabling smoother acceleration, better fuel economy, cruise control, traction control and user defined power modes.
						</p>
						<p>
							This puts renewed emphasis on the design of power unit and transmission systems which coupled with the need
							to embrace electric, hybrid and e-fuels requires detailed analysis to move the two-stroke and four-stroke
							two-wheel machines into the markets of the future, particularly with Euro 5+ legislation coming in 2024.
						</p>
						<p>
							At Realis Simulation we have extensive experience of using software to streamline the motorcycle design process –
							answering many of the ‘What ifs’ using computer simulation before OEMs (Original Equipment Manufacturers) and
							Tier 1 suppliers commit to expensive physical prototypes.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Solutions")}
						className="widget__sibling__pages">
						<AssetList>
							{MOTORCYCLE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page>
	);
};

export default MarketMotorcyclePage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["markets-motorcycle", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/markets/motorcycle/Realis-Simulation_1920_0023.jpg" }) {
			...imageBreaker
		}
		resources: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/resources/" }
				fields: { language: { eq: $language } }
				frontmatter: { markets: { in: "Motorcycle" } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 6
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}
	}
`;
